/* import __COLOCATED_TEMPLATE__ from './answer.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent-2 */

import Component from '@glimmer/component';
import PALETTE from '@intercom/pulse/lib/palette';
import {
  CustomerAnswerRating,
  Status,
  type PlaygroundQuestion,
} from 'embercom/lib/fin-playground/question';
import { KNOWLEDGE_HUB_ENTITIES_NAMES } from 'embercom/lib/knowledge-hub/constants';

interface FinAnswerComponentArgs {
  appId: string;
  question?: PlaygroundQuestion;
  generateAnswer: () => void;
  playgroundIsLoading: boolean;
}

type FinAnswerComponentSignature = {
  Args: FinAnswerComponentArgs;
  Element: HTMLDivElement;
};

export default class AiAgentPlaygroundFinAnswer extends Component<FinAnswerComponentSignature> {
  CustomerAnswerRating = CustomerAnswerRating;
  Status = Status;

  entityName(entityType: keyof typeof KNOWLEDGE_HUB_ENTITIES_NAMES) {
    return KNOWLEDGE_HUB_ENTITIES_NAMES[entityType];
  }

  get answer() {
    return this.args.question?.responseText;
  }

  get dotColor() {
    return `background-color: ${PALETTE['neutral-fill-emphasis']};`;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::Playground::FinAnswer::Answer': typeof AiAgentPlaygroundFinAnswer;
  }
}
